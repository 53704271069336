.mat-mdc-table {
    & th,
    .mat-mdc-header-cell {
        // Adding in to keep old sytling from Material Upgrade
        font-size: 12px;
        font-weight: 500;

        & div.mat-sort-header-arrow {
            display: none;
        }
    }
}
