// Use import below to add variables to component scss
//@import "frontend/src/scss/variables.scss";

$old-primary-color: #074569;
$old-accent-color: #f58100;
$old-success: #008000;

// New design properties
$primary-color: #0288f3;
$accent-color: #cce7fd;
$link-color: #5eb3f7;
$warn-color: #f3615a;
$success-color: #9ef955;

$body-background: #fcfcfc;

$text-color: #707070;
$dark-text-color: #878787;
$light-text-color: #a6a6a6;

$divider-color: #e6e6e6;

$icon-color: #c9c9c9;

$invalid: #f89e99;
$valid: #c3fc96;

$override-color: #6a51a3;

$mask-background: #e6e6e6;

$selected-color: #e5f3fd;

$hover-color: #f5f5f5;
$unselected-chip: #f5f5f5;

// Background
$selected-button: #e6e6e6;

// From Material Theme
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// Base page heights
$banner-height: 23px;
$header-height: 72px;
$sidebar-width: 72px;

// Old Palettes
$primary: (
    50: lighten($old-primary-color, 50%),
    100: lighten($old-primary-color, 40%),
    200: lighten($old-primary-color, 30%),
    300: lighten($old-primary-color, 20%),
    400: lighten($old-primary-color, 10%),
    500: $old-primary-color,
    600: darken($old-primary-color, 10%),
    700: darken($old-primary-color, 20%),
    800: darken($old-primary-color, 30%),
    900: darken($old-primary-color, 40%),
    A100: darken($old-primary-color, 50%),
    A200: darken($old-primary-color, 60%),
    A400: darken($old-primary-color, 70%),
    A700: darken($old-primary-color, 80%),
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: white,
        A400: white,
        A700: white,
    ),
);
$accent: (
    50: lighten($old-accent-color, 50%),
    100: lighten($old-accent-color, 40%),
    200: lighten($old-accent-color, 30%),
    300: lighten($old-accent-color, 20%),
    400: lighten($old-accent-color, 10%),
    500: $old-accent-color,
    600: darken($old-accent-color, 10%),
    700: darken($old-accent-color, 20%),
    800: darken($old-accent-color, 30%),
    900: darken($old-accent-color, 40%),
    A100: darken($old-accent-color, 50%),
    A200: darken($old-accent-color, 60%),
    A400: darken($old-accent-color, 70%),
    A700: darken($old-accent-color, 80%),
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: white,
        A400: white,
        A700: white,
    ),
);
$warn: (
    50: #fce3e0,
    100: #f7b9b3,
    200: #f28a80,
    300: #ed5b4d,
    400: #e93726,
    500: #e51400,
    600: #e21200,
    700: #de0e00,
    800: #da0b00,
    900: #d30600,
    A100: #fffbfb,
    A200: #ffc8c8,
    A400: #ff9595,
    A700: #ff7c7b,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// New Palettes
$primary-palette: (
    50: lighten($primary-color, 50%),
    100: lighten($primary-color, 40%),
    200: lighten($primary-color, 30%),
    300: lighten($primary-color, 20%),
    400: lighten($primary-color, 10%),
    500: $primary-color,
    600: darken($primary-color, 10%),
    700: darken($primary-color, 20%),
    800: darken($primary-color, 30%),
    900: darken($primary-color, 40%),
    A100: darken($primary-color, 50%),
    A200: darken($primary-color, 60%),
    A400: darken($primary-color, 70%),
    A700: darken($primary-color, 80%),
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: white,
        A400: white,
        A700: white,
    ),
);
$accent-palette: (
    50: lighten($accent-color, 50%),
    100: lighten($accent-color, 40%),
    200: lighten($accent-color, 30%),
    300: lighten($accent-color, 20%),
    400: lighten($accent-color, 10%),
    500: $accent-color,
    600: darken($accent-color, 10%),
    700: darken($accent-color, 20%),
    800: darken($accent-color, 30%),
    900: darken($accent-color, 40%),
    A100: darken($accent-color, 50%),
    A200: darken($accent-color, 60%),
    A400: darken($accent-color, 70%),
    A700: darken($accent-color, 80%),
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: white,
        A400: white,
        A700: white,
    ),
);
$warn-palette: (
    50: lighten($warn-color, 50%),
    100: lighten($warn-color, 40%),
    200: lighten($warn-color, 30%),
    300: lighten($warn-color, 20%),
    400: lighten($warn-color, 10%),
    500: $warn-color,
    600: darken($warn-color, 10%),
    700: darken($warn-color, 20%),
    800: darken($warn-color, 30%),
    900: darken($warn-color, 40%),
    A100: darken($warn-color, 50%),
    A200: darken($warn-color, 60%),
    A400: darken($warn-color, 70%),
    A700: darken($warn-color, 80%),
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: white,
        600: white,
        700: white,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: white,
        A400: white,
        A700: white,
    ),
);
