@import 'frontend/src/scss/variables.scss';

$table-background: #eee;
$borderColor: #f5f5f5;

$fillShadow: rgba(174, 172, 172, 0.25);

.hide-expanded-row {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &.show-expanded-row {
        padding: 10px 0;
        max-height: 500px;
        opacity: 1;
        overflow-y: auto;
    }
}

table.mat-mdc-table {
    th {
        font-family: Roboto, sans-serif;
        color: #afafaf !important;
        padding: 10px 0;

        .sort-header {
            display: flex;
            gap: 0.25rem;
            align-items: center;
        }
    }

    th.center {
        text-align: center;

        .mat-sort-header-container {
            justify-content: center;
        }
    }

    td.center {
        text-align: center;
    }

    td,
    th {
        //    //width: 150px;
        //    //max-width: 150px;
        //    //min-width: 150px;
        padding-right: 10px;
    }

    *.ei-sm-column {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        padding-right: 10px;
    }

    *.ei-md-column {
        width: 200px;
        max-width: 200px;
        min-width: 200px;
        padding-right: 10px;
    }

    *.ei-lg-column {
        width: 300px;
        max-width: 300px;
        min-width: 300px;
        padding-right: 10px;
    }

    *.ei-xl-column {
        width: 400px !important;
        max-width: 400px !important;
        min-width: 400px !important;
        padding-right: 10px;
    }

    *.ei-icon-column {
        width: 50px;
        max-width: 50px;
        min-width: 50px;
        padding-right: 10px;
    }

    @for $i from 1 through 10 {
        *.ei-#{$i * 10}-percent-column {
            width: #{$i * 10}#{'%'};
            max-width: #{$i * 10}#{'%'};
        }
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.county-customer {
    color: #a2b9c3;
    font-size: 10px;
    font-style: italic;
}

.new-design table.mat-mdc-table {
    width: 100%;
    padding: 0 10px;
    background: transparent;

    tr {
        background: white;

        &.selected-row {
            background-color: $selected-color;

            &:hover {
                box-shadow: none !important;
            }
        }

        &.mat-header-row {
            height: 25px;

            th {
                white-space: nowrap;
                border-bottom: none;
            }
        }

        td {
            border-radius: 0;
            border-top: 1px solid $borderColor;
            border-bottom: none;

            mat-icon {
                color: $text-color;
            }

            mat-form-field.mat-form-field-appearance-fill {
                *.mat-form-field-infix {
                    border-top: 5px solid transparent;
                    padding-bottom: 8px;
                }
            }

            &:first-child {
                border-left: 1px solid $borderColor;
            }

            &:last-child {
                border-right: 1px solid $borderColor;
            }

            .half-size {
                width: 50%;
            }

            .full-size {
                width: 90%;
            }
        }

        // These two are used by COBSR and UCOOT for column alighment
        th {
            &.right-cell {
                > *:first-child {
                    justify-content: flex-end;
                    padding-right: 16px;
                }
            }
        }

        td {
            &.right-cell {
                text-align: right;

                > *:first-child {
                    padding-right: 20px;
                }
            }
        }

        &.mat-row.hoverable:hover {
            box-shadow: inset 0 0 3px 0 #bbb;
        }

        &:last-child td {
            border-bottom: 1px solid $borderColor !important;
        }

        &.expandable-row {
            height: 0;

            &:hover {
                box-shadow: none;
            }

            td {
                border: none;
            }

            &.expanded td {
                &:first-child {
                    border-left: 1px solid $borderColor;
                }

                &:last-child {
                    border-right: 1px solid $borderColor;
                }

                border-radius: 0;
            }
        }
    }
}

.new-design mat-paginator {
    background: transparent;

    .mat-mdc-paginator-container {
        justify-content: flex-start;
    }

    .mat-mdc-paginator-page-size {
        flex-grow: 1;
    }

    .mat-mdc-paginator-page-size-label {
        display: none;
    }

    .mat-mdc-paginator-range-actions {
        flex-grow: 1;
        position: relative;
    }

    .mat-mdc-paginator-range-label {
        margin: 0;
        position: absolute;
        right: 1rem;
        //top: 50%;
        color: $light-text-color;
    }
}
