@use './variables' as var;

fa-icon {
    font-size: 1rem;
    color: var.$icon-color;
}

mat-chip {
    fa-icon {
        font-size: 1rem;
        margin: 0.25rem;
        vertical-align: middle;
    }
}
