.sidebar-card {
    max-width: 320px;
    padding: 0 !important;
    margin: 5px 5px 10px;
}

.scroll {
    overflow-x: hidden;
}

.hide-filters {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0;
    transition: all 1s ease-in-out;
}

.show-filters {
    max-height: 1000px;
    overflow: hidden;
    opacity: 1;
}

eaglei-sidebar {
    .sidebar-card-container > * {
        border-top: 1px solid #bbb !important;
        padding: 0 10px;
    }

    .hide-card {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 500ms ease-in-out;
    }

    .show-card {
        max-height: 1500px;
        overflow: visible;
        opacity: 1;
    }

    .mat-checkbox-layout {
        margin-bottom: 0;
    }

    .mat-checkbox-label {
        margin-top: 2px;
    }

    & mat-icon.mat-icon-inline.fa-filter {
        font-size: 16px;
    }
}
