.layer-element-padding {
    padding: 0 10px;
}

.layer-header {
    padding: 10px;
}

.layer-dropdown {
    border: 2px solid #bbb;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0;
    min-height: 20px;

    &:hover {
        cursor: pointer;
    }

    .dropdown-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }

    .dropdown-content.show {
        max-height: 1500px;
        overflow: visible;
        opacity: 1;
    }
}

.layer-filters {
    padding: 0 26px;

    &:hover {
        cursor: pointer;
    }
}

.layer-align-right {
    padding-right: 12px;
}

.active-filter {
    color: #fec12d;
}

.inline-filter {
    border-top: 1px dashed black;
    margin-top: 10px;
}

.disable-filter {
    color: grey;
    cursor: default;
    pointer-events: none;
}

.filter-label {
    display: inline-block;
    padding: 5px 0;
    cursor: pointer;
}

.filter-options,
.filter-container {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition-property: all;
    transition-duration: 0.5s;

    &.show {
        max-height: 1000px;
        opacity: 1;
    }
}

.filter-container {
    border-top: 1px dashed black;
    padding: 0 30px;

    .mdi {
        position: absolute;
        top: 5px;
        right: 12px;
    }
}
