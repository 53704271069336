.mat-mdc-text-field-wrapper {
    fa-icon {
        margin-right: 0.5rem;
    }
}

@mixin formFieldSubscriptFix() {
    mat-form-field[subscriptSizing='fixed'] {
        // setting it to "fixed" creates a slightly different height than those set to "dynamic".
        // "Its an older code, sir, but it checks out" - Admiral Piett to Dark Vader
        margin-bottom: calc(-1rem - 1px);

        .mat-mdc-text-field-wrapper {
            height: 43px;
        }
    }
}

.widget-header,
.filter-bar,
eaglei-date-range {
    @include formFieldSubscriptFix();
}
