@use './variables' as var;

.horizontal-legend {
    .legend-element {
        min-height: 20px;
        min-width: 25px;
        border: 1px solid black;
        text-align: center;
    }

    .layer-legend:first-child .legend-element {
        border: 1px solid black;
        border-radius: 5px 5px 0 0;
    }

    .layer-legend:last-child .legend-element {
        border-radius: 0 0 5px 5px;
    }
}

.hide-legend {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 1s ease-in-out;
}

.hide-legend.show-legend {
    max-height: 1000px;
    opacity: 1;
    overflow: hidden;
    padding-bottom: 5px;
}

.legend-text {
    padding: 0 28px;
}

.legend-font {
    font-size: 12px; // 14 pt
}

.legend-padding {
    padding-left: 30px;
}

.legend-circle {
    border-radius: 100%;
    height: 12px;
}

.legend-elements {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    border: 1px solid black;
    margin-right: 5px;
    margin-bottom: 2px;
    margin-top: 5px;
}

.hazard-legend-container {
    font-size: 14px;
}

.legend-container {
    font-size: 14px;
}

.shape-container {
    display: flex;
    gap: 0.25rem;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.legends {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    .icon-container {
        .pole-icon {
            width: 18px;
            height: 18px;
        }

        display: flex;

        .icon {
            width: 15px;
            height: 15px;
            border-radius: 5px;

            &.no-light {
                background-color: rgba(64, 64, 64, 0.6);
                border: 1px solid #404040;
            }

            &.increased {
                // True Color #FFFF00
                // Darken the legend to be a little more readable
                background-color: rgba(255, 255, 0, 0.6);
                border: 1px solid #e5e600;
            }

            &.cloud {
                background-color: rgba(211, 211, 211, 0.6);
                border: 1px solid #d3d3d3;
            }
        }
    }
    span {
        color: var.$text-color;

        &.label {
            font-size: 14px;
            padding-left: 10px;

            &.info {
                padding-left: 8px;
            }

            &.warn {
                color: var.$warn-color !important;
            }
        }

        &.header {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.dot-legend {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .circle {
        border-radius: 100%;
        background-color: #2c7fb8;
        opacity: 0.8;
        height: 16px;
        width: 16px;
        margin-right: 5px;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            color: white;
            transform: translate(-50%, -50%);
            font-size: 12px;
        }
    }

    p {
        color: var.$text-color;
        font-size: 12px;
    }
}

.status-legend {
    .status {
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-right: 5px;

        &.block {
            border-radius: 5px;
        }

        &.active {
            background-color: #02bb75;
        }

        &.future {
            background-color: #1633b5;
        }

        &.past {
            background-color: #5d5d5d;
        }

        &.small-circle {
            width: 20px;
            height: 20px;

            & > mat-icon {
                font-size: 10px;
            }
        }

        &.online {
            background-color: #6dbd28;
        }

        &.offline {
            background-color: #e72724;
        }

        &.impacted {
            background-color: #e9dc00;
        }

        &.fire-icon {
            background-color: #9d120b;
        }

        &.open {
            background-color: #84da7c;
        }

        &.closed {
            border: 2px solid #4f88b5;
            background-image: linear-gradient(
                120deg,
                #4f88b5 20%,
                #ffffff 20%,
                #ffffff 50%,
                #4f88b5 50%,
                #4f88b5 70%,
                #ffffff 70%,
                #ffffff 100%
            );
            background-size: 5px 8.66px;
        }

        &.restricted {
            border: 2px solid #a4561b;
            background-image: linear-gradient(
                120deg,
                #a4561b 20%,
                #ffffff 20%,
                #ffffff 50%,
                #a4561b 50%,
                #a4561b 70%,
                #ffffff 70%,
                #ffffff 100%
            );
            background-size: 5px 8.66px;
        }

        &.both {
            border: 2px solid #6c00e0;
            background-image: linear-gradient(
                120deg,
                #6c00e0 20%,
                #ffffff 20%,
                #ffffff 50%,
                #6c00e0 50%,
                #6c00e0 70%,
                #ffffff 70%,
                #ffffff 100%
            );
            background-size: 5px 8.66px;
        }

        &.none {
            background-color: #c8c8c8;
        }

        &.small-circle {
            width: 20px;
            height: 20px;

            & > mat-icon {
                font-size: 10px;
            }
        }

        > mat-icon {
            color: white;
            font-size: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: initial;
            height: initial;
        }
    }

    span {
        color: var.$text-color;

        &.label {
            font-size: 14px;
        }

        &.header {
            font-size: 16px;
            display: inline-block;
            margin-bottom: 10px;
        }
    }

    .sub-header {
        color: var.$light-text-color;
        font-size: 12px;
    }

    .circle-container {
        margin-bottom: 10px;
        display: flex;
        gap: 0.25rem;

        .status {
            display: flex;
            justify-content: center;
            align-items: center;

            fa-icon {
                font-size: 15px;
            }

            &.small-circle {
                fa-icon {
                    font-size: 13px;
                }
            }
        }
    }

    .h-divider {
        display: inline-block;
        margin-right: 5px;
    }
}

.line-legend {
    .classification {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }

    .sml-text {
        font-size: 14px;
    }

    .flow-low {
        width: 10px;
        height: 2px;
        background-color: #079b80;
    }

    .flow-med {
        width: 10px;
        height: 4px;
        background-color: #f2814c;
    }

    .flow-high {
        width: 10px;
        height: 6px;
        background-color: #9b1bc5;
    }
}

.box-legend {
    span.label {
        color: var.$light-text-color;
        font-size: 14px;
        line-height: 16px;
        display: inline-block;

        &.no-margin-bottom {
            margin-bottom: 0;
        }

        &.small {
            font-size: 12px;
            line-height: 14px;
        }
    }

    .legend-label {
        color: var.$text-color;
        font-size: 14px;
    }

    .legend-row {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }

    .sml-text {
        font-size: 14px;
    }

    .legend-box {
        width: 15px;
        height: 15px;
        border: solid 1px #6e6e6e;

        &.area-color-0 {
            background-color: #f0ecaa;
        }

        &.area-color-1 {
            background-color: #c9bc87;
        }

        &.area-color-2 {
            background-color: #a69165;
        }

        &.area-color-3 {
            background-color: #856b49;
        }

        &.area-color-4 {
            background-color: #664830;
        }
    }
}

.radr-legend {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    & *.color-box {
        flex: 0 0 15px;
        border-radius: 4px;
    }

    & *.radr-legend-text {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;

        & span {
            color: #707070;
            font-size: 10px;
        }
    }
    .color-box {
        background: rgb(53, 59, 162);
        background: linear-gradient(90deg, rgba(53, 59, 162, 1) 0%, rgba(141, 62, 70, 1) 100%);
    }
}
