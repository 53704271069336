.popover-container {
    min-width: 300px;
    max-width: 800px;

    .popover-title {
        text-align: center;
    }

    .dark-line td {
        background-color: #ccc !important;
    }

    .row-padding {
        padding: 10px;
        background-color: white;
    }

    .extra-padding {
        padding-right: 20px;
    }

    .popover-label {
        vertical-align: top;
        padding-left: 10px;
        white-space: nowrap;
    }

    .popover-data {
        padding-right: 10px;
    }

    .no-wrap {
        white-space: nowrap;
    }
}
