@import 'frontend/src/scss/variables.scss';

$menu-text-color: #575757;

.base-page-system-notification {
    padding: 15px;
    width: 400px;

    & .mat-mdc-menu-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        & > * {
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    & header {
        color: $menu-text-color;
        font-size: 14px;
        line-height: 16px;
    }

    & .notification-date {
        font-size: 12px;
        line-height: 14px;
        color: $menu-text-color;
        margin-bottom: 10px;
    }

    & .notification {
        padding-bottom: 10px;

        &.seen {
            & * {
                color: lightgrey !important;
            }
        }

        &:last-child {
            padding-bottom: 0;
        }

        & *.type {
            font-size: 12px;
            line-height: 14px;
            color: $primary-color;
            margin-bottom: 3px;
        }

        & *.message {
            font-size: 14px;
            line-height: 19px;
            color: $text-color;

            &.multiline-ellipsis {
                line-height: 1.2em !important;
                margin-right: 0 !important;
            }
        }
    }
}
