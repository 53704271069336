@import 'frontend/src/scss/variables.scss';

gridster.gird {
    background-color: $body-background;

    &.display-grid {
        background-color: rgba(0, 0, 0, 0.25);
    }

    .gridster-column,
    .gridster-row {
        border-color: #000 !important;
    }
}

gridster-item {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    padding: 0 10px;
    border-radius: 6px;

    .widget-header,
    .widget-content {
        padding: 0 10px;
    }

    .widget-header {
        $header-height: 60px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 2px solid $divider-color;
        height: $header-height;
        min-height: $header-height;

        &.large {
            height: calc(#{$header-height} + 25px);
            min-height: calc(#{$header-height} + 25px);
        }

        .title {
            font-family: Roboto, sans-serif;
        }

        .attribution {
            color: $light-text-color;
            font-size: 12px;
            height: 20px;
        }
    }

    mat-paginator {
        button.mat-icon-button {
            color: $icon-color !important;
        }

        svg.mat-paginator-icon {
            width: 20px !important;
        }
    }
}

// Widget Content Classes
.widget-content {
    position: relative;

    &.high-level-metric {
        padding: 40px 20px;

        .metric-value {
            font-size: 50px;
            font-weight: 300;
            color: #7c7c7c;
        }

        .metric-text {
            font-size: 12px;
            color: #7c7c7c;
            padding-right: 0.5em;
        }

        mat-icon.link-icon {
            margin: 0 auto;
            display: block;
            padding: 15px 0 0;
            width: 30px;
            color: $icon-color;
            font-size: 30px;
        }

        .link-section {
            text-align: center;

            a {
                color: #a9a9a9;
                text-decoration: none;
                font-size: 11px;
            }
        }
    }
}

gridster.mobile gridster-item {
    min-height: 300px;
}
